/* PaymentSuccess.css */
.animated-checkmark {
    font-size: 4rem;
    animation: bounce 1s infinite alternate;
  }
  
  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  