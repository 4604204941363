/* Settings Modal specific styles */
.settings-modal .modal-content {
  background-color: var(--900);
  color: var(--100);
  border-radius: 0.75rem;
  padding: 1rem;
}

/* Nav container */
.nav-pills {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;  /* Spread items across width */
  width: 100%;  /* Ensure nav takes full width */
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

/* Nav items should grow to fill space */
.nav-pills .nav-item {
  flex: 1;  /* Each item takes equal space */
  margin: 0 0.25rem;  /* Small gap between items */
}

/* Nav links should fill their container */
.nav-pills .nav-link {
  color: var(--accent);
  padding: 0.5rem 1rem;
  background-color: white;
  border: 2px solid var(--800);
  border-radius: 0.375rem;
  transition: all 0.2s ease;
  width: 100%;  /* Make links fill their container */
  text-align: center;  /* Center text */
  display: block;  /* Ensure link takes full width */
}

.nav-pills .nav-link:hover {
  background-color: white;
}

.nav-pills .nav-link.active {
  background-color: var(--800);
  color: var(--100);
  border-color: var(--800);
}

.settings-modal .tab-content {
  padding: 1rem 0;
}

.settings-modal .tab-pane {
  min-height: 300px;
}