.if-section {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    position: relative;
    background-color: #f8f9fa; /* Light background color */
  }
  
  .condition-buttons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  
  .if-button {
    margin-right: 10px;
  }
  
  .if-conditions {
    margin-top: 20px;
  }
  
  .if-condition {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 10px 0; /* Add some padding for better spacing */
  }
  
  .if-condition > * {
    margin-right: 10px;
    flex-grow: 1;
  }
  
  .operator-dropdown {
    margin-bottom: 10px;
  }
  
  .add-condition-button {
    width: 150px;
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .remove-condition-button {
    background-color: #dc3545;
    border: none;
    padding: 10px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  