/* 1) Make the main container align with the dark background and white text. */
.home-container {
  /* Remove the white background so it inherits the dark site background */
  /* background-color: var(--900); */ /* Optional if your body is already dark */
  padding: 2rem; /* consistent spacing */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* If you want to add a subtle accent border or shadow around the container:
     border: 1px solid #ffc44d;
     border-radius: 0.75rem; 
     box-shadow: 0 0 10px #00000050;
  */
}

/* 2) Style your “Empty State” area to match the dark theme. 
   Use the accent color for headings or emojis. */
.text-center.my-5 h1 {
  color: var(--accent); /* #ffc44d */
  font-size: 4rem;
}
.text-center.my-5 h3 {
  color: var(--100); /* White text */
  margin-bottom: 1rem;
}

/* Example for the “Or” text to use accent color or be slightly lighter. */
.text-center.my-5 p {
  color: var(--300); /* Lighter text, or use var(--accent) if you prefer */
}

/* 3) Rule cards consistent with your Webflow “card-item” style. */
.rule-card {
  background-color: var(--800);  /* #171918 dark background */
  border-radius: 0.75rem;        /* rounding */
  padding: 1.5rem;              
  margin: 1rem;                 /* spaced from each other */
  color: var(--100);            /* white text */
  box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* subtle shadow */
  flex-grow: 1;
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Optional: If the rule card has a title or heading, color it accent. */
.rule-card .rule-title {
  color: var(--accent); /* #ffc44d */
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

/* 4) Buttons can match the rest of your site’s accent button style. */
.button,
.btn-primary {
  background-color: var(--accent) !important;  /* #ffc44d */
  color: var(--900) !important;                /* dark text on yellow */
  border: none !important; 
  font-weight: 500;
  transition: opacity 0.2s ease;
}
.button:hover,
.btn-primary:hover {
  opacity: 0.85;
}
.button:active,
.btn-primary:active {
  opacity: 0.75;
  transform: scale(0.97);
}

/* 5) Tweak the CustomNavTabs if needed, e.g., give them a darker container or 
   highlight the active tab with accent color. 
   Only do this if you want a dark tab bar. If you prefer the current white, ignore. 
*/
.w-full.mb-4 .flex.gap-1.border-b {
  border-color: var(--accent);
}
.w-full.mb-4 button {
  background-color: var(--700);   /* #202221 darkish background */
  color: var(--100);             /* white text */
  border-radius: 0.5rem;
  border: 1px solid transparent;
}
.w-full.mb-4 button:hover {
  background-color: var(--600);
  color: var(--accent);
}
.w-full.mb-4 button.bg-white {
  /* If activeTab sets a “bg-white” class, you might override it: */
  background-color: var(--800) !important;
  color: var(--accent) !important;
  border-bottom: 2px solid var(--accent);
}
